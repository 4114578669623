import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../service/axios';
import iphoneImage from '../assets/iphone.png';
import {
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Button
} from '@mui/material';
import { keyframes } from '@mui/system';
import Navbar from '../components/navbar';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const Home = () => {
  const [promocoes, setPromocoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axios.get('/pedido/listarPromoApp');
        const data = response.data;
        // Se a API retornar uma promoção única, encapsulamos em um array
        setPromocoes(data.promo ? [data.promo] : []);
      } catch (error) {
        console.error('Erro ao obter dados da API:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // Função para tratar o clique no card ou botão
  const handleComprar = (id_promocao) => {
    navigate('/opcoesCompra/'+id_promocao);
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="md" sx={{ padding: 4, textAlign: 'center' }}>
        <Typography
          variant="h4"
          sx={{ marginBottom: 3, fontWeight: 'bold', color: '#1976d2' }}
        >
          Promoções Exclusivas
        </Typography>
        {loading ? (
          <CircularProgress sx={{ marginTop: 4 }} />
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {promocoes.map((promo, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    textAlign: 'center',
                    padding: 2,
                    cursor: 'pointer',
                    transition: 'transform 0.2s',
                    '&:hover': { transform: 'scale(1.03)' }
                  }}
                  onClick={() => handleComprar(promo.id_promocao)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 180
                    }}
                  >
                    <img
                      src={iphoneImage}
                      alt={promo.descricao}
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </Box>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                      {promo.descricao}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1, color: '#555' }}>
                      {promo.observacao}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        marginTop: 2,
                        animation: `${blink} 1s infinite`,
                        fontWeight: 'bold'
                      }}
                      onClick={(e) => {
                        // Evita que o clique no botão propague para o onClick do card
                        e.stopPropagation();
                        handleComprar(promo.id_promocao);
                      }}
                    >
                      Comprar
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Home;
