// src/components/Login.js
import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/logo-super-top-bet.png'; // Verifique se o arquivo existe em src/assets

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [codigo, setCodigo] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!cpf ) {
      setError('Por favor, preencha todos os campos.');
      return;
    }
    
    // Remove a máscara do CPF (deixando apenas os números)
    const cpfSemMascara = cpf.replace(/\D/g, '');

    try {
      await login({ username: cpfSemMascara });
      
      // Recupera a URL salva no localStorage, caso exista
      const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
      localStorage.removeItem('redirectAfterLogin'); // Limpa a URL após o redirecionamento
      navigate(redirectUrl, { replace: true });
    } catch (err) {
      setError('Usuário ou senha inválidos.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Card sx={{ width: '100%', p: 3, boxShadow: 3 }}>
          <CardContent>
            {/* Logo da empresa */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <img src={logo} alt="Company Logo" style={{ width: '150px' }} />
            </Box>
            <Typography variant="h4" align="center" gutterBottom>
              Bem-vindo
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Faça login para acessar sua conta
            </Typography>
            {/* Mensagem de erro exibida acima do formulário */}
            {error && (
              <Typography color="error" variant="body2" align="center" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="CPF"
                name="cpf"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="Digite seu CPF"
              />
            
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button onClick={() => navigate('/registrar')} color="secondary">
              Ainda não tem conta? Registre-se
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default Login;
