// src/App.js
import React from 'react';
import { Routes, Route ,Navigate } from 'react-router-dom';
import Home from './components/home';
import Login from './components/login';
import Registrar from './components/registrar';
import OpcaoCompra from './components/opcaoCompra';
import Logout from './components/logout';
import ProtectedRoute from './components/ProtectedRoute';
import Checkout from './components/checkout';
import MeusBilhetes from './components/meusBilhetes';
import MeusBilhetesDetail from './components/detalheBilhete';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/registrar" element={<Registrar />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="/opcoesCompra"
        element={
          <ProtectedRoute>
            <OpcaoCompra />
          </ProtectedRoute>
        }
      />

<Route
        path="/opcoesCompra/:idPromocao/:codigoVendedor"
        element={
          <ProtectedRoute>
            <OpcaoCompra />
          </ProtectedRoute>
        }
      />

<Route
        path="/opcoesCompra/:idPromocao"
        element={
          <ProtectedRoute>
            <OpcaoCompra />
          </ProtectedRoute>
        }
      />
  <Route
        path="/checkout"
        element={
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute>
        }
      />
   <Route
        path="/meusBilhetes/:numeroPedido"
        element={
          <ProtectedRoute>
            <MeusBilhetesDetail />
          </ProtectedRoute>
        }
      />
       <Route
        path="/meusBilhetes"
        element={
          <ProtectedRoute>
            <MeusBilhetes />
          </ProtectedRoute>
        }
      />
<Route path="/session/signin" element={<Navigate to="/" replace />} />
<Route path="/material/stb/opcoesCompra" element={<Navigate to="/" replace />} />



    </Routes>
  );
}

export default App;
