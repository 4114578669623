// src/components/OpcaoCompra.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axiosBack from '../service/axios';
import {
  Container,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Button,
  TextField
} from '@mui/material';
import Navbar from '../components/navbar';
import { useAuth } from '../context/AuthContext';

const OpcaoCompra = () => {
  const [listaItemPromocao, setListaItemPromocao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [codigoVendedor, setCodigoVendedor] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { idPromocao: idPromoParam, codigoVendedor: codigoVendedorParam } = useParams();
  const { user } = useAuth();

  // Obtenha o idPromocao e o código do vendedor da URL (useParams) ou do state (fallback)
  const idPromocao = idPromoParam || location.state?.id_promocao || '';
  const idVendedorFromRoute = codigoVendedorParam || location.state?.idVendedor || '';

  // Atualiza o estado se o código do vendedor foi passado
  useEffect(() => {
    if (idVendedorFromRoute) {
      setCodigoVendedor(idVendedorFromRoute);
      localStorage.setItem('codigoVendedor', idVendedorFromRoute);
    }
  }, [idVendedorFromRoute]);

  // Busca os itens da promoção usando o idPromocao
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axiosBack.get(`/pedido/detalhesPromoApp/${idPromocao}`);
        const data = response.data;
        setListaItemPromocao(data.promoProduto || []);
      } catch (error) {
        console.error('Erro ao obter dados da API:', error);
      } finally {
        setLoading(false);
      }
    }
    if (idPromocao) {
      localStorage.setItem('id_promocao', idPromocao);
      fetchData();
    }
  }, [idPromocao]);

  return (
    <>
      <Navbar />
      <Container
        maxWidth="md"
        sx={{
          padding: { xs: 2, sm: 4, md: 6 },
          textAlign: 'center',
          backgroundColor: '#f9f9f9',
          minHeight: '100vh'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: 3,
            fontWeight: 'bold',
            color: '#1976d2',
            fontSize: { xs: '1.8rem', sm: '2rem', md: '2.2rem' }
          }}
        >
          Escolha sua Opção de Compra
        </Typography>
        {loading ? (
          <CircularProgress sx={{ marginTop: 4 }} />
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {listaItemPromocao.map((produto, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    textAlign: 'center',
                    padding: 2,
                    cursor: 'pointer',
                    transition: 'transform 0.2s',
                    '&:hover': { transform: 'scale(1.03)' },
                    backgroundColor: '#fff'
                  }}
                  onClick={() =>
                    navigate('/checkout', {
                      state: {
                        id_produto: produto.id_produto,
                        id_promocao: idPromocao,
                        codigoVendedor
                      }
                    })
                  }
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: { xs: 150, sm: 180, md: 200 },
                      marginBottom: 2,
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      src={produto.url_imagem}
                      alt={produto.descricao}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        borderRadius: 8
                      }}
                    />
                  </Box>
                  <CardContent sx={{ padding: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'bold', color: '#333', mb: 1, fontSize: { xs: '1rem', sm: '1.1rem' } }}
                    >
                      {produto.descricao}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
                      Valor: R$ {produto.valor}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontWeight: 'bold',
                        paddingX: 3,
                        paddingY: 1,
                        animation: 'blink 1s infinite',
                        '@keyframes blink': {
                          '0%': { opacity: 1 },
                          '50%': { opacity: 0.5 },
                          '100%': { opacity: 1 }
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/checkout', {
                          state: {
                            id_produto: produto.id_produto,
                            id_promocao: idPromocao,
                            codigoVendedor
                          }
                        });
                      }}
                    >
                      Comprar
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        <Box sx={{ marginTop: 4, textAlign: 'center' }}>
          <TextField
            label="Código do Vendedor"
            variant="outlined"
            fullWidth
            value={codigoVendedor}
            onChange={(e) => {
              setCodigoVendedor(e.target.value);
              localStorage.setItem('codigoVendedor', e.target.value);
            }}
            sx={{
              maxWidth: { xs: '90%', sm: '70%', md: '50%' },
              margin: '0 auto'
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default OpcaoCompra;
