// src/context/authContext.jsx
import React, { createContext, useContext, useState } from 'react';
import axios from '../service/axios';
import { encryptData, decryptData } from '../service/cryptoUtils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Tenta restaurar o usuário do localStorage (descriptografando os dados)
  const [user, setUser] = useState(() => {
    const storedData = localStorage.getItem('currentUser');
    return storedData ? decryptData(storedData) : null;
  });

  // Função de login que consome a API de autenticação e armazena os dados criptografados
  const login = async ({ username, password }) => {
    try {
      // Remove quaisquer caracteres que não sejam dígitos (remove máscara do CPF)
      const cpfLimpo = username.replace(/\D/g, '');
      // Pega os 5 primeiros dígitos do CPF limpo para usar como senha
      const novaSenha = cpfLimpo.slice(0, 5);
  
      const headers = { 'X-TENANT-ID': 'public' };
      const response = await axios.post(
        '/authenticate/authenticate',
        { username, password: novaSenha },
        { headers }
      );
      
      // Armazena os dados criptografados no localStorage
      localStorage.setItem('currentUser', encryptData(response.data));
      setUser(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  

  const logout = () => {
    setUser(null);
    localStorage.removeItem('currentUser');
    // Se necessário, remova tokens ou limpe outras informações de sessão
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para facilitar o uso do contexto em outros componentes
export const useAuth = () => useContext(AuthContext);
