// src/components/MeusBilhetes.jsx
import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import { useAuth } from '../context/AuthContext';
import axiosBack from '../service/axios';
import { QRCodeCanvas } from 'qrcode.react';

const MeusBilhetes = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  // Busca os pedidos do usuário
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axiosBack.get(`/pedido/listarPedidosPorUsuario/${user.user.id}`);
        setOrders(response.data);
      } catch (error) {
        console.error('Erro ao carregar pedidos:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.user && user.user.id) {
      fetchOrders();
    }
  }, [user]);

  // Função para cancelar um pedido manualmente
  const handleCancel = async (numeroPedido) => {
    try {
      await axiosBack.post(`/pedido/cancelarPedido/${numeroPedido}`);
      // Após cancelar, atualiza os pedidos
      const response = await axiosBack.get(`/pedido/listarPedidosPorUsuario/${user.user.id}`);
      setOrders(response.data);
    } catch (error) {
      console.error('Erro ao cancelar pedido:', error);
    }
  };

  // Função para baixar a nota fiscal (DANFE)
  const handleBaixarDanfe = async (numeroPedido) => {
    try {
      const response = await axiosBack.get(`/pedido/baixarDanfe/${numeroPedido}`, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `danfe_${numeroPedido}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error('Erro ao baixar a nota:', error);
    }
  };

  // Função para baixar o Ebook (ZIP)
  const handleBaixarEbook = async (numero) => {
    try {
      const response = await axiosBack.get(`/pedido/baixarEbook/${numero}`, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ebook_${numero}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error('Erro ao baixar o ebook:', error);
    }
  };

  // Função para ir para os detalhes do bilhete
  const handleVerDetalhes = (numero) => {
    navigate(`/meusBilhetes/${numero}`);
  };

  // Função para copiar o QR Code com fallback para iOS e outros navegadores
  const handleCopyQRCode = (qrCodeValue) => {
    if (navigator.clipboard && window.isSecureContext) {
      // Uso do Clipboard API
      navigator.clipboard.writeText(qrCodeValue)
        .then(() => alert('QR Code copiado!'))
        .catch(() => alert('Falha ao copiar!'));
    } else {
      // Fallback para navegadores que não suportam o Clipboard API (incluindo iOS)
      const textArea = document.createElement('textarea');
      textArea.value = qrCodeValue;
      // Estiliza o elemento para que ele não interfira na interface
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          alert('QR Code copiado!');
        } else {
          alert('Falha ao copiar!');
        }
      } catch (err) {
        console.error('Erro ao copiar:', err);
        alert('Falha ao copiar!');
      }
      document.body.removeChild(textArea);
    }
  };

  // useEffect para cancelar automaticamente pedidos não pagos após 15 minutos
  useEffect(() => {
    orders.forEach((order) => {
      // Apenas para pedidos que não estão pagos e que não foram cancelados pelo cliente
      if (
        order.status.toLowerCase() !== 'pago' &&
        order.status.toLowerCase() !== 'cancelado pelo cliente'
      ) {
        const orderDate = new Date(order.dataGravacao);
        const now = new Date();
        const fifteenMinutes = 15 * 60 * 1000;

        // Se passaram mais de 15 minutos e o pedido ainda não foi cancelado automaticamente
        if (now - orderDate > fifteenMinutes && order.status.toLowerCase() !== 'cancelado') {
          axiosBack
            .post(`/pedido/cancelarPedidoAutomatico/${order.numeroPedido}`)
            .then(() => {
              // Atualiza o estado para refletir o cancelamento
              setOrders((prevOrders) =>
                prevOrders.map((o) =>
                  o.numeroPedido === order.numeroPedido ? { ...o, status: 'cancelado' } : o
                )
              );
            })
            .catch((error) => {
              console.error('Erro ao cancelar automaticamente o pedido:', error);
            });
        }
      }
    });
  }, [orders]);

  useEffect(() => {
   
    const intervalId = setInterval(async () => {
      const response = await axiosBack.get(`/pedido/listarPedidosPorUsuario/${user.user.id}`);
      setOrders(response.data);
    }, 10000); // a cada 10 segundos
    return () => clearInterval(intervalId);
  }, [navigate]);

  

  if (loading) {
    return (
      <>
        <Navbar />
        <Container sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h6">Carregando seus pedidos...</Typography>
        </Container>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Container sx={{ mt: 4, mb: 4 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{ mb: 4, fontWeight: 'bold', color: '#1976d2' }}
        >
          Meus Bilhetes
        </Typography>
        {orders.length === 0 ? (
          <Typography variant="body1" align="center">
            Você ainda não possui pedidos.
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {orders.map((order) => {
              const orderDate = new Date(order.dataGravacao);
              const now = new Date();
              const fifteenMinutes = 15 * 60 * 1000;
              const expired = now - orderDate > fifteenMinutes;

              return (
                <Grid item xs={12} sm={6} md={4} key={order.id}>
                  <Card
                    sx={{
                      height: '100%',
                      borderRadius: 2,
                      boxShadow: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      backgroundColor: '#fff'
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, fontWeight: 'bold', color: '#1976d2' }}
                      >
                        Pedido Nº: {order.numeroPedido}
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Status:</strong>{' '}
                        {order.status.toLowerCase() !== 'pago' && expired
                          ? 'Cancelado'
                          : order.status}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        <strong>Data:</strong> {orderDate.toLocaleString()}
                      </Typography>
                      {order.status.toLowerCase() === 'pago' ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleBaixarDanfe(order.numeroPedido)}
                            sx={{ textTransform: 'none' }}
                          >
                            Baixar Nota
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleBaixarEbook(order.numeroPedido)}
                            sx={{ textTransform: 'none' }}
                          >
                            Baixar Ebook
                          </Button>
                          <Button
                            variant="outlined"
                            color="info"
                            onClick={() => handleVerDetalhes(order.numeroPedido)}
                            sx={{ textTransform: 'none', mt: 1 }}
                          >
                            Ver Detalhes
                          </Button>
                        </Box>
                      ) : (
                        <Box>
                          {order.status.toLowerCase() === 'cancelado pelo cliente' ? (
                            <Typography
                              variant="body2"
                              sx={{ mb: 2, color: 'red', fontWeight: 'bold' }}
                            >
                              Pedido cancelado pelo cliente
                            </Typography>
                          ) : expired ? (
                            <Typography
                              variant="body2"
                              sx={{ mb: 2, color: 'red', fontWeight: 'bold' }}
                            >
                              Pedido cancelado automaticamente
                            </Typography>
                          ) : (
                            <>
                              {order.qrCode && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mb: 2
                                  }}
                                >
                                  {/* Exibe o QR Code graficamente */}
                                  <QRCodeCanvas value={order.qrCode} size={150} />
                                  {/* Exibe o valor do QR Code */}
                                  <Typography
                                    variant="body2"
                                    sx={{ wordBreak: 'break-all', fontFamily: 'monospace', mt: 1 }}
                                  >
                                    {order.qrCode}
                                  </Typography>
                                </Box>
                              )}
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {order.qrCode && (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleCopyQRCode(order.qrCode)}
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Copiar QR Code
                                  </Button>
                                )}
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleCancel(order.numeroPedido)}
                                  sx={{ textTransform: 'none' }}
                                >
                                  Cancelar Pedido
                                </Button>
                              </Box>
                            </>
                          )}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default MeusBilhetes;
