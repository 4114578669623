// src/components/Logout.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Efetua o logout e redireciona para a página de login
    logout();
    navigate('/login');
  }, [logout, navigate]);

  // Opcionalmente, pode retornar um spinner ou mensagem enquanto efetua o logout
  return null;
};

export default Logout;
