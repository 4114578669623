// src/components/Registrar.js
import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from '../service/axios';
import { useAuth } from '../context/AuthContext';

// Componente para máscara de CPF usando react-imask
const TextMaskCPF = React.forwardRef(function TextMaskCPF(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000.000.000-00"
      inputRef={ref}
      unmask={true}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value } })
      }
    />
  );
});

TextMaskCPF.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

// Componente para máscara de Celular usando react-imask
const TextMaskCelular = React.forwardRef(function TextMaskCelular(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00) 00000-0000"
      inputRef={ref}
      unmask={true}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value } })
      }
    />
  );
});

TextMaskCelular.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Registrar = () => {
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth();

  // Validação e exibição do popup com os termos LGPD
  const handleSalvar = (e) => {
    e.preventDefault();

    // CPF e Celular são obrigatórios
    if (!cpf || !celular) {
      setError('Os campos CPF e Celular são obrigatórios.');
      return;
    }

    setError('');
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Ao aceitar os termos, envia os dados para a rota /authenticate/registrar.
  // Se a resposta tiver status 200 ou o corpo da resposta for "Usuário já cadastrado!!",
  // efetua o login e redireciona para /opcoesCompra.
  const handleAceitar = async () => {
    setDialogOpen(false);
    setLoading(true);
    try {
      const payload = { nome, cpf, celular, email };
      const response = await axios.post('/authenticate/registrar', payload);
      console.log(response.status)
      if (
        response.status === 200 
       
      ) {
        // Supondo que o login utiliza o CPF como username e os 5 primeiros dígitos do CPF como password.
        await login({ username: cpf, password: cpf.substring(0, 5) });
        const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
      localStorage.removeItem('redirectAfterLogin'); // Limpa a URL após o redirecionamento
      navigate(redirectUrl, { replace: true });
      }else if(response.status === 400 ){
        await login({ username: cpf, password: cpf.substring(0, 5) });
        const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
      localStorage.removeItem('redirectAfterLogin'); // Limpa a URL após o redirecionamento
      navigate(redirectUrl, { replace: true });
      } else {
        setError('Erro ao registrar. Tente novamente.');
      }
    } catch (err) {
      console.error(err);
      setError('Erro ao registrar. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Card sx={{ width: '100%', p: 3, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h4" align="center" gutterBottom>
              Registrar
            </Typography>
            <Box component="form" onSubmit={handleSalvar} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                fullWidth
                id="nome"
                label="Nome"
                name="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                placeholder="Digite seu nome"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="CPF"
                name="cpf"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="Digite seu CPF"
                InputProps={{
                  inputComponent: TextMaskCPF,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="celular"
                label="Celular"
                name="celular"
                value={celular}
                onChange={(e) => setCelular(e.target.value)}
                placeholder="Digite seu celular"
                InputProps={{
                  inputComponent: TextMaskCelular,
                }}
              />
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu email"
              />
              {error && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                Salvar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Popup de confirmação com os termos LGPD e link para o regulamento */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Aceite os termos</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Typography>
                Respeitamos sigilo das informações com base nas normas da Lei Federal 13.709/2018 da LGPD.
              </Typography>
              <Typography>
                Todas as regras das promoções estão disponíveis no link:
              </Typography>
              <a
                href="https://www.supertopbest.com/regulamento"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.supertopbest.com/regulamento
              </a>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleAceitar} color="primary">
            Aceitar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Registrar;
