// src/components/Navbar.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../context/AuthContext';
import logoImage from '../assets/logo-super-top-bet.png';

const Navbar = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Estado para controlar o menu mobile
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Funções de navegação para cada ação
  const goToMeusBilhetes = () => {
    handleMenuClose();
    navigate('/meusBilhetes');
  };

  const goToLogin = () => {
    handleMenuClose();
    navigate('/login');
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
    navigate('/login');
  };
  const handleHome = () => {
    handleMenuClose();   
    navigate('/');
  };
  

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#1976d2',
        boxShadow: 3,
        py: 1
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* Área do Logo - clicável para ir para Home */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={() => navigate('/')}
        >
          <img src={logoImage} alt="Logo" style={{ height: 60, marginRight: 10 }} />
          {/* Em telas maiores, mostra o nome da empresa */}
        
        </Box>

        {/* Área de ações do usuário */}
        {isMobile ? (
          // Se for mobile, mostra um ícone de menu
          <>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {user ? (
                <>
                 <MenuItem onClick={handleHome}>Home</MenuItem>
                  <MenuItem onClick={goToMeusBilhetes}>Meus Bilhetes</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </>
              ) : (
                <MenuItem onClick={goToLogin}>Login</MenuItem>
              )}
            </Menu>
          </>
        ) : (
          // Em telas maiores, mostra os botões normalmente
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {user ? (
              <>
                <Typography variant="body1" sx={{ display: { xs: 'none', sm: 'block' } }}>
                  Olá, {user.user.name}
                </Typography>
                <Button color="inherit" onClick={() => navigate('/meusBilhetes')}>
                  Meus Bilhetes
                </Button>
                <Button color="inherit" onClick={() => navigate('/')}>
                  Home
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    logout();
                    navigate('/login');
                  }}
                >
                  Sair
                </Button>
              </>
            ) : (
              <Button color="inherit" onClick={() => navigate('/login')}>
                Login
              </Button>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
