// src/components/Checkout.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosBack from '../service/axios';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Button,
  TextField,
  Card,
  CardContent
} from '@mui/material';
import Navbar from '../components/navbar';
import { useAuth } from '../context/AuthContext';
import { QRCodeCanvas } from 'qrcode.react';

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { id_produto, id_promocao, codigoVendedor } = location.state || {};

  // Estado que indica se o usuário aceitou os termos
  const [accepted, setAccepted] = useState(false);

  // Estados para os dados do pedido
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const [qrCode, setQrCode] = useState('');
  const [valor, setValor] = useState('');
  const [copySuccess, setCopySuccess] = useState('');

  // Estado para armazenar o timestamp do pedido (em milissegundos)
  const [orderTimestamp, setOrderTimestamp] = useState(null);
  // Estado para forçar atualização periódica e possibilitar a verificação do tempo decorrido
  const [now, setNow] = useState(Date.now());

  // Atualiza o estado "now" a cada 10 segundos
  useEffect(() => {
    const timer = setInterval(() => {
      setNow(Date.now());
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  // Função para lidar com o aceite dos termos
  const handleAceitar = () => {
    setAccepted(true);
  };

  // Se o usuário não aceitar, redireciona para a página anterior
  const handleCancelar = () => {
    navigate(-1);
  };

  // Passo 1: Buscar os detalhes da promoção para identificar o produto selecionado e obter seu valor.
  useEffect(() => {
    const fetchPromotionDetails = async () => {
      try {
        const response = await axiosBack.get(`/pedido/detalhesPromoApp/${id_promocao}`);
        const data = response.data;
        // Procura o item cujo id_produto corresponda ao selecionado
        const product = data.promoProduto.find(item => item.id_produto === id_produto);
        if (product) {
          setValor(product.valor);
        } else {
          console.error('Produto selecionado não encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes da promoção:', error);
      }
    };

    if (id_promocao && id_produto) {
      localStorage.setItem('id_promocao', id_promocao);
      fetchPromotionDetails();
    }
  }, [id_promocao, id_produto]);

  // Função para processar o pedido (chama as APIs e atualiza os estados)
  const handleProcessOrder = async () => {
    setLoading(true);
    try {
      // Chama a API /pedido/pedidoSic
      const payloadSic = {
        valor,
        codigoProduto: id_produto,
        cpf: user.user.cpf
      };
      const responseSic = await axiosBack.post('/pedido/pedidoSic', payloadSic);
      const { idPedido, pix,txId ,numeroPedido} = responseSic.data;
      setOrderId(numeroPedido);
      setQrCode(pix);
      
      // Chama a API /pedido/salvarPedido
      const payloadSalvar = {
        numero: idPedido,
        status: "Aguardando pagamento",
        pix: true,
        idItemPromocao: id_produto,
        idPromocao: id_promocao,
        vendedor: codigoVendedor,
        idUsuario: user.user.id,
        qrCode: pix,
        txId:txId,
        numeroPedido:numeroPedido
      };
      await axiosBack.post('/pedido/salvarPedido', payloadSalvar);
      // Inicialmente, define o timestamp como o momento do processamento;
      // este valor poderá ser atualizado no polling com a data de gravação vinda do servidor.
      setOrderTimestamp(Date.now());
    } catch (error) {
      console.error('Erro ao processar o pedido:', error);
    } finally {
      setLoading(false);
    }
  };

  // Executa o processamento automaticamente se o usuário aceitou, o valor foi definido e o pedido ainda não foi processado
  useEffect(() => {
    if (accepted && valor && !orderId) {
      handleProcessOrder();
    }
  }, [accepted, valor, orderId, id_promocao, id_produto, codigoVendedor, user]);

  // Função para regenerar o QR Code (pode ser chamada manualmente via botão)
  const handleRegenerateQRCode = async () => {
    await handleProcessOrder();
  };

  // Passo 3: Polling a cada 10 segundos para consultar o status do pedido
  useEffect(() => {
    if (!orderId) return;
    const intervalId = setInterval(async () => {
      try {
        const response = await axiosBack.get(`/pedido/consultarPedido/${orderId}`);
        if (response.data) {
          // Atualiza o orderTimestamp com a data de gravação retornada pelo servidor
          if (response.data.dataGravacao) {
            setOrderTimestamp(new Date(response.data.dataGravacao).getTime());
          }
          if (
            response.data.status &&
            response.data.status.toLowerCase() === "pago"
          ) {
            clearInterval(intervalId);
            navigate(`/meusBilhetes/${orderId}`);
          }
        }
      } catch (error) {
        console.error('Erro ao consultar pedido:', error);
      }
    }, 10000); // a cada 10 segundos
    return () => clearInterval(intervalId);
  }, [orderId, navigate]);

  // Função para copiar o QR Code para a área de transferência com fallback para iOS
  const handleCopy = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(qrCode)
        .then(() => setCopySuccess('QR Code copiado!'))
        .catch(() => setCopySuccess('Falha ao copiar!'));
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = qrCode;
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setCopySuccess('QR Code copiado!');
        } else {
          setCopySuccess('Falha ao copiar!');
        }
      } catch (err) {
        console.error('Erro ao copiar', err);
        setCopySuccess('Falha ao copiar!');
      }
      document.body.removeChild(textArea);
    }
  };

  // Se o usuário ainda não aceitou os termos, exibe a tela de aceite
  if (!accepted) {
    return (
      <>
        <Navbar />
        <Container sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
          <Card sx={{ p: { xs: 3, sm: 4, md: 5 }, borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                Aceite os Termos
              </Typography>
              <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                Respeitamos o sigilo das informações com base nas normas da Lei Federal 13.709/2018 da LGPD.
                Todas as regras das promoções estão disponíveis no link:
              </Typography>
              <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                <a
                  href="https://www.supertopbest.com/regulamento"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.supertopbest.com/regulamento
                </a>
              </Typography>
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-around' }}>
                <Button variant="contained" color="secondary" onClick={handleCancelar}>
                  Cancelar
                </Button>
                <Button variant="contained" color="primary" onClick={handleAceitar}>
                  Aceitar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </>
    );
  }

  // Se o pedido ainda estiver sendo processado ou o valor não foi definido, mostra um indicador de carregamento
  if (loading || !valor) {
    return (
      <>
        <Navbar />
        <Container sx={{ mt: 4, textAlign: 'center' }}>
          <CircularProgress />
        </Container>
      </>
    );
  }

  // Após o aceite e com o pedido processado, exibe as informações
  // Se já se passaram 15 minutos (com base na data do servidor), não mostra mais o QR Code
  // e exibe somente a mensagem e o botão para regenerar o QR Code.
  const expired = orderTimestamp && now - orderTimestamp >= 900000; // 15 minutos em milissegundos

  return (
    <>
      <Navbar />
      <Container sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <Card sx={{ p: { xs: 3, sm: 4, md: 5 }, borderRadius: 2, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" align="center" sx={{ mb: 2 }}>
              Obrigado pela sua compra!
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 3 }}>
              Valor: R$ {valor}
            </Typography>

            {!expired ? (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Escaneie o QR Code abaixo para efetuar o pagamento:
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: 2,
                      p: 2,
                      backgroundColor: '#fff',
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: { xs: '200px', sm: '250px', md: '300px' },
                      height: { xs: '200px', sm: '250px', md: '300px' }
                    }}
                  >
                    <QRCodeCanvas value={qrCode} size={250} />
                  </Box>
                </Box>

                <Button variant="contained" color="primary" onClick={handleCopy} sx={{ mb: 2 }}>
                  Copiar Código
                </Button>

                {copySuccess && (
                  <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
                    {copySuccess}
                  </Typography>
                )}

                <TextField
                  label="Código do QR Code"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={qrCode}
                  InputProps={{ readOnly: true }}
                  sx={{
                    maxWidth: { xs: '90%', sm: '70%', md: '50%' },
                    mx: 'auto',
                    mb: 2
                  }}
                />

                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Estamos verificando o pagamento, por favor aguarde...
                </Typography>
              </>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="error" align="center" sx={{ mb: 1 }}>
                  Seu QR Code expirou. Se você ainda não efetuou o pagamento, clique abaixo para gerar um novo QR Code. Caso já tenha pago, não se preocupe.
                </Typography>
                <Button variant="contained" color="secondary" onClick={handleRegenerateQRCode}>
                  Gerar QR Code novamente
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Checkout;
