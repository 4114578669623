// src/components/MeusBilhetesDetail.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosBack from '../service/axios';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  Grid
} from '@mui/material';
import Navbar from './navbar';

const MeusBilhetesDetail = () => {
  const { numeroPedido } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  // Ao montar, chama a API para obter os detalhes do pedido
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axiosBack.get(`/pedido/consultarPedido/${numeroPedido}`);
        setOrder(response.data);
      } catch (error) {
        console.error('Erro ao buscar detalhes do pedido:', error);
      } finally {
        setLoading(false);
      }
    };
    if (numeroPedido) {
      fetchOrder();
    }
  }, [numeroPedido]);

  // Função para baixar o PDF (Nota/Danfe)
  const handleBaixarDanfe = async () => {
    try {
      const response = await axiosBack.get(`/pedido/baixarDanfe/${order.numeroPedido}`, {
        responseType: 'blob'
      });
      // Cria um objeto Blob a partir da resposta
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      // Define o nome do arquivo para download (exemplo: danfe_123.pdf)
      link.setAttribute('download', `danfe_${order.numeroPedido}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar a nota:', error);
    }
  };

  // Função para baixar o Ebook (arquivo ZIP)
  const handleBaixarEbook = async () => {
    try {
      const response = await axiosBack.get(`/pedido/baixarEbook/${order.numeroPedido}`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      // Define o nome do arquivo para download (exemplo: ebook_123.zip)
      link.setAttribute('download', `ebook_${order.numeroPedido}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o ebook:', error);
    }
  };

  if (loading) {
    return (
      <>
        <Navbar />
        <Container sx={{ mt: 4, textAlign: 'center' }}>
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (!order) {
    return (
      <>
        <Navbar />
        <Container sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h6">Nenhum pedido encontrado.</Typography>
        </Container>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Container sx={{ mt: 4, mb: 4 }}>
        <Card sx={{ p: { xs: 3, sm: 4, md: 5 }, borderRadius: 2, boxShadow: 3, backgroundColor: '#fff' }}>
          <CardContent>
            <Typography variant="h5" align="center" sx={{ mb: 2, fontWeight: 'bold', color: '#1976d2' }}>
              Pedido Nº {order.numeroPedido}
            </Typography>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="text.secondary">
                  Status:
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {order.status}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="text.secondary">
                  Data:
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {new Date(order.dataGravacao).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>

            {order.status.toLowerCase() === "pago" ? (
              <>
                <Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleBaixarDanfe}
                      sx={{ py: 1.5 }}
                    >
                      Baixar Nota
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={handleBaixarEbook}
                      sx={{ py: 1.5 }}
                    >
                      Baixar Ebook
                    </Button>
                  </Grid>
                </Grid>
                {order.cupomSorte && (
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      border: '1px dashed #1976d2',
                      borderRadius: 2,
                      backgroundColor: '#e3f2fd'
                    }}
                  >
                    <Typography variant="subtitle1" align="center" sx={{ mb: 1, fontWeight: 'bold' }}>
                      Cupom Sorte
                    </Typography>
                    {/* Renderiza o conteúdo HTML formatado */}
                    <Typography
                      variant="body2"
                      align="center"
                      sx={{ whiteSpace: 'pre-wrap' }}
                      dangerouslySetInnerHTML={{ __html: order.cupomSorte }}
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Seu pedido ainda não foi pago. Utilize o QR Code abaixo para efetuar o pagamento:
                </Typography>
                {order.qrCode ? (
                  <Box
                    sx={{
                      border: '2px dashed #1976d2',
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: '#f5f5f5'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        wordBreak: 'break-all',
                        fontFamily: 'monospace'
                      }}
                    >
                      {order.qrCode}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="body2" align="center">
                    QR Code não disponível.
                  </Typography>
                )}
              </Box>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default MeusBilhetesDetail;
