// src/service/axios.js
import axios from 'axios';
import { encryptData, decryptData } from './cryptoUtils';

// Cria uma instância do Axios
const api = axios.create({
  baseURL:  'https://rest.integracenter.com.br/topBackEnd',
  //baseURL:  'http://localhost:8090/',
  //baseURL:  'http://192.168.2.5:8090/',
  
  
});

// Função para buscar o token do localStorage (descriptografando os dados)
const getTokenFromStorage = () => {
  try {
    const encryptedData = localStorage.getItem('currentUser');
    if (!encryptedData) return null;
    // Descriptografa os dados armazenados
    const currentUser = decryptData(encryptedData);
    // Retorna o token, se existir
    return currentUser && currentUser.token ? currentUser.token : null;
  } catch (error) {
    console.error('Erro ao buscar o token do localStorage:', error);
    return null;
  }
};

// Intercepta as requisições para adicionar o token no cabeçalho
api.interceptors.request.use(
  (config) => {
    const token = getTokenFromStorage(); // Busca o token do localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
